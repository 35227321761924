import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { Button } from "../../components/Button";
import { ContactForm } from "../../components/ContactForm";
import { Footer } from "../../components/Footer";
import { HeroSection } from "../../components/HeroSection";
import { HighlightedCta } from "../../components/HighlightedCta";
import { Mensajes } from "../../components/Mensajes";
import { Navbar } from "../../components/Navbar";
import { Socials12 } from "../../icons/Socials12";
import { Socials13 } from "../../icons/Socials13";
import "./style.css";

export const LandingPage = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="landing-page"
      style={{
        minWidth:
          screenWidth < 780
            ? "375px"
            : screenWidth >= 780 && screenWidth < 1024
            ? "780px"
            : screenWidth >= 1024
            ? "1024px"
            : undefined,
      }}
    >
      <Navbar
        screen={
          screenWidth >= 1024
            ? "desktop"
            : screenWidth < 780
            ? "mobile"
            : screenWidth >= 780 && screenWidth < 1024
            ? "tablet"
            : undefined
        }
        variant={
          screenWidth >= 1024
            ? "four"
            : screenWidth < 780
            ? "five"
            : screenWidth >= 780 && screenWidth < 1024
            ? "two"
            : undefined
        }
        variantScreenClassName={`${
          (screenWidth >= 1024 || (screenWidth >= 780 && screenWidth < 1024)) && "highlighted-CTA-instance"
        } ${screenWidth < 780 && "footer-instance"}`}
      />
      {((screenWidth >= 780 && screenWidth < 1024) || screenWidth < 780) && (
        <>
          <HeroSection
            className={`${screenWidth < 780 && "class-71"} ${
              screenWidth >= 780 && screenWidth < 1024 && "highlighted-CTA-instance"
            }`}
            mensajesImg={
              screenWidth < 780
                ? "/img/polygon-1-25.svg"
                : screenWidth >= 780 && screenWidth < 1024
                ? "/img/polygon-1-18.svg"
                : undefined
            }
            mensajesPolygon={screenWidth < 780 ? "/img/polygon-1-24.svg" : undefined}
            mensajesPolygon1={screenWidth < 780 ? "/img/polygon-1-26.svg" : undefined}
            mensajesPolygon2={
              screenWidth < 780
                ? "/img/polygon-1-26.svg"
                : screenWidth >= 780 && screenWidth < 1024
                ? "/img/polygon-1-17.svg"
                : undefined
            }
            mensajesPolygon3={screenWidth >= 780 && screenWidth < 1024 ? "/img/polygon-1-18.svg" : undefined}
            mensajesPolygon4={screenWidth >= 780 && screenWidth < 1024 ? "/img/polygon-1-17.svg" : undefined}
            mobileMockupButtonClassName={`${screenWidth < 780 && "class-72"}`}
            mobileMockupButtonClassName1={`${screenWidth >= 780 && screenWidth < 1024 && "class-78"}`}
            mobileMockupButtonClassName2={`${screenWidth >= 780 && screenWidth < 1024 && "class-74"}`}
            mobileMockupButtonClassName3={`${screenWidth >= 780 && screenWidth < 1024 && "class-70"}`}
            mobileMockupButtonClassName4={`${screenWidth >= 780 && screenWidth < 1024 && "class-68"}`}
            mobileMockupButtonClassNameOverride={`${screenWidth < 780 && "class-74"}`}
            mobileMockupDeviceClassName={`${screenWidth < 780 && "class-69"}`}
            mobileMockupDivClassName={`${screenWidth < 780 && "class-73"}`}
            mobileMockupDivClassNameOverride={`${screenWidth < 780 && "class-77"}`}
            mobileMockupImg={
              screenWidth >= 780 && screenWidth < 1024 ? "/img/insert-your-mockup-image-here-1.png" : undefined
            }
            mobileMockupInsertYourMockup={screenWidth < 780 ? "/img/insert-your-mockup-image-here-1.png" : undefined}
            mobileMockupOverlapGroupClassName={`${screenWidth < 780 && "class-76"}`}
            mobileMockupTopSpeakerClassName={`${screenWidth < 780 && "class-75"}`}
            screen={screenWidth < 780 ? "mobile" : screenWidth >= 780 && screenWidth < 1024 ? "tablet" : undefined}
            variant="hero-1"
            visualsClassName={`${screenWidth < 780 && "class-79"}`}
          />
          <HighlightedCta
            className={`${screenWidth < 780 && "footer-instance"} ${
              screenWidth >= 780 && screenWidth < 1024 && "class-81"
            }`}
            contentClassName={`${screenWidth < 780 && "class-83"}`}
            group={screenWidth < 780 ? "/img/group-5-1.png" : undefined}
            groupClassName={`${screenWidth < 780 && "class-82"}`}
            highlightedCtaClassName={`${screenWidth < 780 && "class-80"}`}
            img={screenWidth >= 780 && screenWidth < 1024 ? "/img/group-4-1.png" : undefined}
            mockupClassName={`${screenWidth < 780 && "class-79"}`}
            screen={screenWidth < 780 ? "mobile" : screenWidth >= 780 && screenWidth < 1024 ? "tablet" : undefined}
            variant="one"
          />
          <ContactForm
            className={`${screenWidth < 780 && "footer-instance"} ${
              screenWidth >= 780 && screenWidth < 1024 && "highlighted-CTA-instance"
            }`}
            icon={screenWidth >= 780 && screenWidth < 1024 ? <Socials12 className="socials-12" /> : undefined}
            img={screenWidth >= 780 && screenWidth < 1024 ? "/img/x-3.svg" : undefined}
            override={screenWidth >= 780 && screenWidth < 1024 ? <Socials13 className="socials-13" /> : undefined}
            screen={screenWidth < 780 ? "mobile" : screenWidth >= 780 && screenWidth < 1024 ? "tablet" : undefined}
            x={screenWidth < 780 ? "/img/x-5.svg" : undefined}
          />
          <Footer
            className={`${screenWidth < 780 && "footer-instance"} ${
              screenWidth >= 780 && screenWidth < 1024 && "highlighted-CTA-instance"
            }`}
            img={screenWidth >= 780 && screenWidth < 1024 ? "/img/social-links-4.svg" : undefined}
            screen={screenWidth < 780 ? "mobile" : screenWidth >= 780 && screenWidth < 1024 ? "tablet" : undefined}
            socialLinks={screenWidth < 780 ? "/img/social-links-5.svg" : undefined}
          />
        </>
      )}

      {screenWidth >= 1024 && (
        <>
          <div className="hero-section-2">
            <img className="img-4" alt="Img" src="/img/img-3.png" />
            <img className="img-5" alt="Img" src="/img/img-2.png" />
            <div className="header-16">
              <div className="header-17">
                <p className="meet-spontaneously-4">
                  <span className="text-wrapper-37">
                    Meet
                    <br />
                  </span>
                  <span className="text-wrapper-38">spontaneously</span>
                </p>
                <p className="text-wrapper-39">
                  Turn every trip into an adventure. Connect with nearby travelers and experience spontaneous moments
                  together.
                </p>
                <div className="group-3">
                  <div className="overlap-group-4">
                    <Button
                      buttonBaseLabelClassName="button-19"
                      buttonBaseSizeSmIconNoneClassName="button-18"
                      buttonBaseText="NEW"
                      className="button-17"
                      color="primary"
                      icon="none"
                      size="xl"
                    />
                    <Button
                      buttonBaseLabelClassName="button-19"
                      buttonBaseSizeSmIconNoneClassName="button-21"
                      buttonBaseText="TRAVEL 🗺️"
                      className="button-20"
                      color="primary"
                      icon="none"
                      size="xl"
                    />
                    <Button
                      buttonBaseLabelClassName="button-19"
                      buttonBaseSizeSmIconNoneClassName="button-23"
                      buttonBaseText="APP"
                      className="button-22"
                      color="primary"
                      icon="none"
                      size="xl"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="visuals-3">
              <div className="mobile-mockup-34">
                <div className="overlap-group-5">
                  <div className="button-24" />
                  <div className="button-25" />
                  <div className="button-26" />
                  <div className="button-27" />
                  <div className="device-2" />
                  <div className="jed-villejo-wrapper">
                    <img
                      className="jed-villejo-3"
                      alt="Jed villejo"
                      src="/img/jed-villejo-4sbyp8kiooe-unsplash-1.png"
                    />
                  </div>
                  <div className="top-speaker-2">
                    <div className="rectangle-10" />
                    <div className="ellipse-21" />
                  </div>
                </div>
              </div>
            </div>
            <img className="IMG-5" alt="Img" src="/img/img-3517-1.png" />
            <Mensajes
              chatClassName="mensajes-25"
              className="mensajes-24"
              divClassNameOverride="mensajes-27"
              hasDiv={false}
              hasRectangle={false}
              img="/img/polygon-1-16.svg"
              text="Bet, be there in 5 :)"
              textClassName="mensajes-26"
              type="otro"
              visible={false}
            />
            <Mensajes
              chatClassName="mensajes-29"
              className="mensajes-28"
              divClassNameOverride="mensajes-27"
              hasDiv={false}
              hasRectangle={false}
              img="/img/polygon-1-17.svg"
              text="Yes, see you there!"
              textClassName="mensajes-26"
              type="otro"
              visible={false}
            />
            <Mensajes
              chatClassName="mensajes-32"
              className="mensajes-30"
              divClassName="mensajes-33"
              hasFrame={false}
              polygon="/img/polygon-1-18.svg"
              rectangleClassName="mensajes-31"
              text="Hey guys!! I am getting ready"
              type="t"
            />
            <Mensajes
              chatClassName="mensajes-32"
              className="mensajes-34"
              divClassName="mensajes-33"
              hasFrame={false}
              polygon="/img/polygon-1-18.svg"
              rectangleClassName="mensajes-31"
              text="Should we meet at the entrance?"
              type="t"
            />
          </div>
          <HighlightedCta
            className="highlighted-CTA-instance"
            group1="/img/group-3-1.png"
            screen="desktop"
            variant="one"
          />
          <ContactForm
            className="contact-form-instance"
            icon1={<Socials12 className="socials-12" />}
            icon2={<Socials13 className="socials-13" />}
            screen="desktop"
            x1="/img/x-3.svg"
          />
          <Footer className="footer-instance" screen="desktop" socialLinks1="/img/social-links-3.svg" />
        </>
      )}
    </div>
  );
};
