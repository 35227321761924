/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Socials1 = ({ className }) => {
  return (
    <svg
      className={`socials-1 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.999 8.52901C18.1926 8.56804 16.5066 8.0217 15.0614 7.00708V13.9924C15.0614 16.646 13.3754 18.9874 10.8063 19.924C8.27725 20.8606 5.38695 20.1581 3.62066 18.1679C1.81422 16.1387 1.49308 13.2899 2.77766 10.9485C4.06223 8.64608 6.71168 7.35829 9.4414 7.70951V11.2217C8.19697 10.8314 6.8321 11.2607 6.06939 12.2753C5.34681 13.329 5.34681 14.6948 6.10953 15.7094C6.87225 16.724 8.23711 17.1533 9.4414 16.7631C10.6858 16.3728 11.5288 15.2411 11.5288 13.9924V0.333984H15.0614C15.0614 0.646176 15.0614 0.919343 15.1417 1.23154C15.3826 2.51933 16.1453 3.65102 17.3094 4.35345C18.0722 4.86076 19.0356 5.13393 19.999 5.13393V8.52901Z"
        fill="black"
      />
    </svg>
  );
};
