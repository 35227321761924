/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Socials11 = ({ className }) => {
  return (
    <svg
      className={`socials-11 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.5705 0.833984C19.3294 0.833984 19.999 1.50363 19.999 2.3072V19.4054C19.999 20.209 19.3294 20.834 18.5705 20.834H1.38295C0.624023 20.834 -0.000976562 20.209 -0.000976562 19.4054V2.3072C-0.000976562 1.50363 0.624023 0.833984 1.38295 0.833984H18.5705ZM6.02581 17.9768V8.46791H3.07938V17.9768H6.02581ZM4.5526 7.12863C5.4901 7.12863 6.24902 6.3697 6.24902 5.4322C6.24902 4.4947 5.4901 3.69113 4.5526 3.69113C3.57045 3.69113 2.81152 4.4947 2.81152 5.4322C2.81152 6.3697 3.57045 7.12863 4.5526 7.12863ZM17.1419 17.9768V12.7536C17.1419 10.209 16.5615 8.20006 13.5705 8.20006C12.1419 8.20006 11.1597 9.00363 10.758 9.76256H10.7133V8.46791H7.90081V17.9768H10.8472V13.2893C10.8472 12.0393 11.0705 10.834 12.633 10.834C14.1508 10.834 14.1508 12.2626 14.1508 13.334V17.9768H17.1419Z"
        fill="black"
      />
    </svg>
  );
};
