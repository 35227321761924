import React from "react";

export const Socials13 = ({ className }) => {
  return (
    <svg
      className={`socials-13 ${className}`}
      fill="none"
      height="23"
      viewBox="0 0 22 23"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_502_15021)">
        <path
          className="path"
          d="M19.999 8.75972C18.1926 8.79875 16.5066 8.25241 15.0614 7.23779V14.2231C15.0614 16.8767 13.3754 19.2181 10.8063 20.1547C8.27725 21.0913 5.38695 20.3889 3.62066 18.3986C1.81422 16.3694 1.49308 13.5206 2.77766 11.1792C4.06223 8.8768 6.71168 7.58901 9.4414 7.94022V11.4524C8.19697 11.0621 6.8321 11.4914 6.06939 12.506C5.34681 13.5597 5.34681 14.9255 6.10953 15.9401C6.87225 16.9548 8.23711 17.384 9.4414 16.9938C10.6858 16.6035 11.5288 15.4718 11.5288 14.2231V0.564697H15.0614C15.0614 0.876889 15.0614 1.15006 15.1417 1.46225C15.3826 2.75004 16.1453 3.88173 17.3094 4.58416C18.0722 5.09147 19.0356 5.36464 19.999 5.36464V8.75972Z"
          fill="black"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_502_15021">
          <rect className="rect" fill="white" height="22" transform="translate(0 0.230713)" width="22" />
        </clipPath>
      </defs>
    </svg>
  );
};
