import PropTypes from "prop-types";
import React from "react";
import { Envelopesimple9 } from "../../icons/Envelopesimple9";
import { Phonecall } from "../../icons/Phonecall";
import { User7 } from "../../icons/User7";
import "./style.css";

export const InputField = ({ message = "Message", text = "Full Name", type, className }) => {
  return (
    <div className={`input-field ${type} ${className}`}>
      {["email", "name", "phone"].includes(type) && (
        <>
          <div className="icon">
            {type === "name" && <User7 className="instance-node-2" />}

            {type === "email" && <Envelopesimple9 className="instance-node-2" />}

            {["email", "name"].includes(type) && <div className="divider" />}

            {type === "phone" && (
              <>
                <Phonecall className="instance-node-2" />
                <div className="divider" />
              </>
            )}
          </div>
          <input className="full-name" placeholder={type === "name" ? text : type === "email" ? "Email" : "Phone"} 
 />
          
        </>
      )}

{type === "message" && (
        <textarea
          className="input"
          placeholder={message}
          rows="7" // You can adjust the number of rows to control the textarea height
        />
)}
    </div>
  );
};

InputField.propTypes = {
  message: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(["message", "phone", "name", "email"]),
};
