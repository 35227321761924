/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { MobileMockup } from "../MobileMockup";
import "./style.css";

export const HighlightedCta = ({
  screen,
  variant,
  className,
  highlightedCtaClassName,
  contentClassName,
  groupClassName,
  group = "/img/group-5.png",
  mockupClassName,
  img = "/img/group-4.png",
  group1 = "/img/group-3.png",
}) => {
  return (
    <div className={`highlighted-CTA screen-49-${screen} variant-2-${variant} ${className}`}>
      {["four", "one", "two"].includes(variant) && (
        <div className={`div-2 ${highlightedCtaClassName}`}>
          {["one", "two"].includes(variant) && (
            <>
              <div className={`content ${contentClassName}`}>
                {variant === "one" && (
                  <>
                    <div className="made-for-travelers-wrapper">
                      <div className="made-for-travelers">
                        <span className="span-2">
                          {screen === "desktop" && (
                            <>
                              <br />
                              Made for <br />
                              travelers who
                            </>
                          )}

                          {["mobile", "tablet"].includes(screen) && (
                            <>
                              Made for <br />
                              travelers who <br />
                            </>
                          )}
                        </span>
                        <span className="span-3">
                          {["mobile", "tablet"].includes(screen) && <>go with the flow</>}

                          {screen === "desktop" && (
                            <>
                              {" "}
                              <br />
                            </>
                          )}
                        </span>
                        {screen === "desktop" && (
                          <p className="span-wrapper">
                            <span className="text-wrapper-23">go with the flow</span>
                          </p>
                        )}
                      </div>
                    </div>
                    <img
                      className={`group-2 ${groupClassName}`}
                      alt="Group"
                      src={screen === "mobile" ? group : screen === "desktop" ? group1 : img}
                    />
                  </>
                )}

                {variant === "two" && (
                  <>
                    <div className="text-wrapper-24">Subscribe now</div>
                    <p className={`text-wrapper-25 ${groupClassName}`}>
                      Never miss a beat on new landing page designs and features.
                    </p>
                  </>
                )}
              </div>
              <div className={`mockup-3 ${mockupClassName}`}>
                {variant === "one" && (
                  <p className="text-wrapper-26">
                    Discover activities near you and connect instantly for unforgettable experiences.
                  </p>
                )}

                {variant === "two" && ["desktop", "tablet"].includes(screen) && <input className="enter-your-email" />}

                {variant === "two" && screen === "mobile" && <input className="enter-your-email-wrapper" />}

              </div>
            </>
          )}

          {variant === "four" && (
            <>
              <div className="header-9">
                <p className="text-wrapper-27">Get Landing Page UI Kit Today!</p>
                <p className="text-wrapper-28">Break Figma limits and explore the endless possibilities with Anima.</p>
              </div>
              <div className="CT-as-6">
                <div className="button-wrapper">
                </div>
              </div>
              <div className="desktop-mockup-wrapper">
                <div className="desktop-mockup-2">
                  <div className="desktop-gray-3">
                    <img
                      className="mask-group-4"
                      alt="Mask group"
                      src={
                        screen === "tablet"
                          ? "/img/mask-group-4.png"
                          : screen === "mobile"
                          ? "/img/mask-group-5.png"
                          : "/img/mask-group.png"
                      }
                    />
                    <div className="top-speaker-cam-4">
                      <div className="rectangle-8" />
                      <div className="ellipse-14" />
                      <div className="ellipse-15" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {variant === "five" && (
        <>
          <div className="header-10">
            <p className="text-wrapper-29">Get Landing Page UI Kit Today!</p>
            <p className="text-wrapper-30">Break Figma limits and explore the endless possibilities with Anima.</p>
          </div>
          <div className="CT-as-6">
            <div className="button-wrapper">
            </div>
          </div>
          <div className="mockup-4">
            <div className="desktop-mockup-3">
              <div className="desktop-gray-4">
                <img
                  className="mask-group-5"
                  alt="Mask group"
                  src={
                    screen === "tablet"
                      ? "/img/mask-group-2.png"
                      : screen === "mobile"
                      ? "/img/mask-group-3.png"
                      : "/img/mask-group-1.png"
                  }
                />
                <div className="top-speaker-cam-5">
                  <div className="rectangle-9" />
                  <div className="ellipse-16" />
                  <div className="ellipse-17" />
                </div>
              </div>
            </div>
            <img
              className="subtract-5"
              alt="Subtract"
              src={
                screen === "tablet"
                  ? "/img/subtract-2.svg"
                  : screen === "mobile"
                  ? "/img/subtract-4.svg"
                  : "/img/subtract.svg"
              }
            />
            <img
              className="subtract-6"
              alt="Subtract"
              src={
                screen === "tablet"
                  ? "/img/subtract-3.svg"
                  : screen === "mobile"
                  ? "/img/subtract-5.svg"
                  : "/img/subtract-1.svg"
              }
            />
          </div>
        </>
      )}

      {variant === "three" && (
        <>
          <div className="overlap-wrapper">
            <div className="overlap-5">
              <div className="ellipse-18" />
              <div className="ellipse-19" />
              <div className="mobile-mockup-instance-wrapper">
                <MobileMockup
                  buttonClassName={`${screen === "desktop" ? "class-60" : "class-61"}`}
                  buttonClassNameOverride={`${screen === "desktop" ? "class-40" : "class-41"}`}
                  className={`${screen === "desktop" ? "class-42" : "class-43"}`}
                  color="black"
                  deviceClassName={`${screen === "desktop" ? "class-52" : "class-53"}`}
                  divClassName={`${screen === "desktop" ? "class-46" : "class-47"}`}
                  divClassNameOverride={`${screen === "desktop" ? "class-54" : "class-55"}`}
                  ellipseClassName={`${screen === "desktop" ? "class-48" : "class-49"}`}
                  insertYourScreenClassName={`${screen === "desktop" ? "class-62" : "class-63"}`}
                  jedVillejoClassName={`${screen === "desktop" ? "class-56" : "class-57"}`}
                  overlapGroupClassName={`${screen === "desktop" ? "class-44" : "class-45"}`}
                  rectangleClassName={`${screen === "desktop" ? "class-58" : "class-59"}`}
                  topSpeakerClassName={`${screen === "desktop" ? "class-50" : "class-51"}`}
                />
              </div>
              <div className="ellipse-20" />
            </div>
          </div>
          <div className="header-11">
            <div className="header-12">
              <p className="text-wrapper-31">Get Landing Page UI Kit Today!</p>
              <p className="text-wrapper-32">
                Break the Figma limits and explore the endless possibilities with Anima.
              </p>
            </div>
            
          </div>
        </>
      )}
    </div>
  );
};

HighlightedCta.propTypes = {
  screen: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
  variant: PropTypes.oneOf(["two", "three", "four", "one", "five"]),
  group: PropTypes.string,
  img: PropTypes.string,
  group1: PropTypes.string,
};
