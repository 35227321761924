/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { EssentialIcon16 } from "../../icons/EssentialIcon16";
import { EssentialIcon49 } from "../../icons/EssentialIcon49";
import "./style.css";

export const ButtonBase = ({
  size,
  icon,
  className,
  labelClassName,
  text = "Button",
  labelClassNameOverride,
  essentialIcon16Color = "white",
  essentialIcon16Stroke = "white",
  divClassName,
  essentialIcon16Color1 = "white",
  essentialIcon49Color = "white",
  essentialIcon49Stroke = "white",
  essentialIcon49Color1 = "white",
}) => {
  return (
    <div className={`button-base ${size} ${icon} ${className}`}>
      {((icon === "leading" && size === "lg") ||
        (icon === "leading" && size === "md") ||
        (icon === "leading" && size === "sm") ||
        (icon === "leading" && size === "xs") ||
        (icon === "only" && size === "lg") ||
        (icon === "only" && size === "md") ||
        (icon === "only" && size === "sm") ||
        (icon === "only" && size === "xs")) && (
        <EssentialIcon16
          className={`${size === "xs" ? "class" : "class-2"}`}
          color={icon === "only" ? essentialIcon16Color1 : essentialIcon16Stroke}
        />
      )}

      {((icon === "leading" && size === "lg") ||
        (icon === "leading" && size === "md") ||
        (icon === "leading" && size === "sm") ||
        (icon === "leading" && size === "xs") ||
        icon === "none" ||
        icon === "trailing") && (
        <div
          className={`label ${
            icon === "trailing"
              ? labelClassNameOverride
              : icon === "leading"
              ? divClassName
              : icon === "none"
              ? labelClassName
              : undefined
          }`}
        >
          {text}
        </div>
      )}

      {size === "xl" && ["leading", "only", "trailing"].includes(icon) && (
        <EssentialIcon49
          className="essential-icon"
          color={
            icon === "trailing" ? essentialIcon49Color : icon === "only" ? essentialIcon49Color1 : essentialIcon49Stroke
          }
        />
      )}

      {icon === "leading" && size === "xl" && <div className={`label-2 ${divClassName}`}>{text}</div>}

      {((icon === "trailing" && size === "lg") ||
        (icon === "trailing" && size === "md") ||
        (icon === "trailing" && size === "sm") ||
        (icon === "trailing" && size === "xs")) && (
        <EssentialIcon16 className={`${size === "xs" ? "class" : "class-2"}`} color={essentialIcon16Color} />
      )}
    </div>
  );
};

ButtonBase.propTypes = {
  size: PropTypes.oneOf(["sm", "xs", "lg", "xl", "md"]),
  icon: PropTypes.oneOf(["none", "leading", "only", "trailing"]),
  text: PropTypes.string,
  essentialIcon16Color: PropTypes.string,
  essentialIcon16Stroke: PropTypes.string,
  essentialIcon16Color1: PropTypes.string,
  essentialIcon49Color: PropTypes.string,
  essentialIcon49Stroke: PropTypes.string,
  essentialIcon49Color1: PropTypes.string,
};
