/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Phonecall = ({ className }) => {
  return (
    <svg
      className={`phonecall ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.22656 9.74999C7.8697 11.0781 8.94366 12.1493 10.2734 12.789C10.3715 12.8355 10.48 12.8556 10.5882 12.8474C10.6965 12.8392 10.8007 12.8029 10.8906 12.7422L12.8437 11.4375C12.93 11.379 13.0298 11.3432 13.1336 11.3337C13.2374 11.3241 13.342 11.341 13.4375 11.3828L17.0938 12.9531C17.2187 13.0051 17.3231 13.0968 17.3909 13.2139C17.4586 13.3311 17.486 13.4673 17.4688 13.6015C17.3529 14.506 16.9114 15.3373 16.2269 15.9398C15.5424 16.5423 14.6619 16.8748 13.75 16.875C10.9321 16.875 8.22956 15.7556 6.23699 13.763C4.24442 11.7704 3.125 9.06791 3.125 6.24999C3.12521 5.33811 3.45767 4.45755 4.06018 3.77308C4.66269 3.0886 5.49395 2.64712 6.39844 2.53124C6.53269 2.51399 6.66888 2.54136 6.78605 2.60912C6.90322 2.67688 6.99487 2.78128 7.04687 2.90624L8.61719 6.5703C8.65802 6.66432 8.67511 6.76694 8.66693 6.86912C8.65876 6.9713 8.62558 7.0699 8.57031 7.15624L7.26562 9.14061C7.20754 9.23036 7.17345 9.33352 7.16661 9.44021C7.15977 9.54689 7.18041 9.65355 7.22656 9.74999Z"
        fill="#219F56"
        opacity="0.2"
      />
      <path
        className="path"
        d="M7.22656 9.74999C7.8697 11.0781 8.94366 12.1493 10.2734 12.789C10.3715 12.8355 10.48 12.8556 10.5882 12.8474C10.6965 12.8392 10.8007 12.8029 10.8906 12.7422L12.8437 11.4375C12.93 11.379 13.0298 11.3432 13.1336 11.3337C13.2374 11.3241 13.342 11.341 13.4375 11.3828L17.0938 12.9531C17.2187 13.0051 17.3231 13.0968 17.3909 13.2139C17.4586 13.3311 17.486 13.4673 17.4688 13.6015C17.3529 14.506 16.9114 15.3373 16.2269 15.9398C15.5424 16.5423 14.6619 16.8748 13.75 16.875C10.9321 16.875 8.22956 15.7556 6.23699 13.763C4.24442 11.7704 3.125 9.06791 3.125 6.24999C3.12521 5.33811 3.45767 4.45755 4.06018 3.77308C4.66269 3.0886 5.49395 2.64712 6.39844 2.53124C6.53269 2.51399 6.66888 2.54136 6.78605 2.60912C6.90322 2.67688 6.99487 2.78128 7.04687 2.90624L8.61719 6.5703C8.65802 6.66432 8.67511 6.76694 8.66693 6.86912C8.65876 6.9713 8.62558 7.0699 8.57031 7.15624L7.26562 9.14061C7.20754 9.23036 7.17345 9.33352 7.16661 9.44021C7.15977 9.54689 7.18041 9.65355 7.22656 9.74999Z"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.740741"
      />
      <path
        className="path"
        d="M12.4532 3.125C13.5133 3.40951 14.4799 3.96785 15.256 4.74399C16.0322 5.52013 16.5905 6.48676 16.875 7.54687"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.740741"
      />
      <path
        className="path"
        d="M11.8047 5.53905C12.4423 5.70839 13.0237 6.04326 13.4902 6.50974C13.9567 6.97621 14.2916 7.55771 14.4609 8.1953"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.740741"
      />
    </svg>
  );
};
