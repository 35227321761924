/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Mensajes = ({
  type,
  className,
  rectangleClassName,
  polygon = "/img/polygon-1-14.svg",
  chatClassName,
  divClassName,
  text = "Text",
  hasFrame = true,
  hasRectangle = true,
  img = "/img/polygon-1-12.svg",
  textClassName,
  hasDiv = true,
  divClassNameOverride,
  visible = true,
}) => {
  return (
    <div className={`mensajes ${type} ${className}`}>
      {hasRectangle && (
        <>
          <>
            {["otro-foto", "otro"].includes(type) && <div className={`rectangle-2 ${rectangleClassName}`} />}

            {["t-foto", "t"].includes(type) && (
              <img className={`polygon ${rectangleClassName}`} alt="Polygon" src={polygon} />
            )}
          </>
        </>
      )}

      <div className={`chat ${chatClassName}`}>
        {["otro-foto", "otro", "t-foto"].includes(type) && (
          <>
            <img className="polygon-2" alt="Polygon" src={type === "t-foto" ? "/img/rectangle-230-1.png" : img} />
            <div className={`text ${["otro-foto", "otro"].includes(type) ? textClassName : undefined}`}>
              {type === "otro" && (
                <>
                  <>{hasDiv && <div className={`text-wrapper-2 ${divClassName}`}>Camila</div>}</>
                  <>
                    {hasFrame && (
                      <div className="frame">
                        <div className={`text-2 ${divClassNameOverride}`}>{text}</div>
                        {visible && <div className="text-wrapper-3">9:31 a.m</div>}
                      </div>
                    )}
                  </>
                </>
              )}

              {type === "otro-foto" && (
                <>
                  <div className="text-wrapper-2">Camila</div>
                  <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-230.png" />
                  <div className="frame">
                    <div className="text-wrapper-4">9:31 a.m</div>
                  </div>
                </>
              )}

              {type === "t-foto" && <div className="text-wrapper-5">9:31 a.m</div>}
            </div>
          </>
        )}

        {type === "t" && (
          <div className={`frame-2 ${textClassName}`}>
            {hasDiv && <div className={`text-2 ${divClassName}`}>{text}</div>}

            {hasFrame && <div className="text-wrapper-5">9:31 a.m</div>}
          </div>
        )}
      </div>
    </div>
  );
};

Mensajes.propTypes = {
  type: PropTypes.oneOf(["t-foto", "t", "otro", "otro-foto"]),
  polygon: PropTypes.string,
  text: PropTypes.string,
  hasFrame: PropTypes.bool,
  hasRectangle: PropTypes.bool,
  img: PropTypes.string,
  hasDiv: PropTypes.bool,
  visible: PropTypes.bool,
};
