/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Button } from "../Button";
import { Mensajes } from "../Mensajes";
import { MobileMockup } from "../MobileMockup";
import "./style.css";

export const HeroSection = ({
  variant,
  screen,
  className,
  visualsClassName,
  mobileMockupOverlapGroupClassName,
  mobileMockupDeviceClassName,
  mobileMockupButtonClassName,
  mobileMockupButtonClassNameOverride,
  mobileMockupDivClassName,
  mobileMockupDivClassNameOverride,
  mobileMockupInsertYourMockup = "/img/insert-your-mockup-image-here.png",
  mobileMockupTopSpeakerClassName,
  mensajesPolygon = "/img/polygon-1-2.svg",
  mensajesImg = "/img/polygon-1-2.svg",
  mensajesPolygon1 = "/img/polygon-1.svg",
  mensajesPolygon2 = "/img/polygon-1.svg",
  mobileMockupButtonClassName1,
  mobileMockupButtonClassName2,
  mobileMockupButtonClassName3,
  mobileMockupButtonClassName4,
  mobileMockupImg = "/img/insert-your-mockup-image-here.png",
  mensajesPolygon3 = "/img/polygon-1-4.svg",
  mensajesPolygon4 = "/img/polygon-1-6.svg",
}) => {
  return (
    <div className={`hero-section ${variant} screen-4-${screen} ${className}`}>
      {screen === "desktop" && variant === "hero-6" && (
        <div className="hero-content">
          <div className="header">
            <div className="header-2">
              <div className="text-wrapper-6">Create Engaging Landing Pages</div>
              <p className="build-beautiful">
                <span className="text-wrapper-7">Build beautiful landing pages in record time with </span>
                <a
                  href="https://www.animaapp.com/?utm_source=figma-samples&amp;utm_campaign=figma-lp-ui-kit&amp;utm_medium=figma-samples"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="text-wrapper-8">Anima</span>
                </a>
                <span className="text-wrapper-7">’s Landing Page UI kit for Figma. No code required!</span>
              </p>
            </div>
          </div>
          <div className="mockup">
            <div className="overlap">
              <div className="overlap-2">
                <div className="rectangle-4" />
                <img className="subtract" alt="Subtract" src="/img/subtract-6.svg" />
                <MobileMockup
                  buttonClassName="mobile-mockup-3"
                  buttonClassNameOverride="mobile-mockup-4"
                  className="mobile-mockup-instance"
                  color="black"
                  deviceClassName="mobile-mockup-7"
                  divClassName="mobile-mockup-5"
                  divClassNameOverride="mobile-mockup-6"
                  ellipseClassName="mobile-mockup-12"
                  insertYourScreenClassName="mobile-mockup-8"
                  jedVillejoClassName="mobile-mockup-9"
                  overlapGroupClassName="mobile-mockup-2"
                  rectangleClassName="mobile-mockup-11"
                  topSpeakerClassName="mobile-mockup-10"
                />
              </div>
              <img className="subtract-2" alt="Subtract" src="/img/subtract-7.svg" />
            </div>
          </div>
        </div>
      )}

      {((screen === "desktop" && variant === "hero-4") ||
        (screen === "mobile" && variant === "hero-6") ||
        (screen === "tablet" && variant === "hero-4") ||
        (screen === "tablet" && variant === "hero-6") ||
        variant === "hero-2" ||
        variant === "hero-3" ||
        variant === "hero-5") && (
        <>
          <div className="header-3">
            {["hero-3", "hero-4", "hero-5", "hero-6"].includes(variant) && (
              <>
                <div className="header-4">
                  {["hero-4", "hero-5", "hero-6"].includes(variant) && (
                    <>
                      <div className="create-engaging">
                        {variant === "hero-6" && <>Create Engaging Landing Pages</>}

                        {variant === "hero-5" && (
                          <p className="text-wrapper-40">Create Stunning Landing Pages Without Code</p>
                        )}

                        {variant === "hero-4" && (
                          <p className="text-wrapper-40">Build Live Landing Pages in Record Time</p>
                        )}
                      </div>
                      <div className="build-beautiful-2">
                        {variant === "hero-6" && (
                          <>
                            <p className="span-wrapper">
                              <span className="text-wrapper-9">Build beautiful landing pages in record time with </span>
                            </p>
                            <p className="span-wrapper">
                              <a
                                href="https://www.animaapp.com/?utm_source=figma-samples&amp;utm_campaign=figma-lp-ui-kit&amp;utm_medium=figma-samples"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <span className="text-wrapper-10">Anima</span>
                              </a>
                            </p>
                            <p className="span-wrapper">
                              <span className="text-wrapper-11">
                                ’s Landing Page UI kit for Figma. No code required!
                              </span>
                            </p>
                          </>
                        )}

                        {variant === "hero-5" && (
                          <p className="text-wrapper-40">
                            Get ready to elevate your design game with Anima&#39;s ultimate landing page UI kit.
                          </p>
                        )}

                        {variant === "hero-4" && (
                          <p className="text-wrapper-40">
                            Say goodbye to endless tweaking - our landing page ui kit is optimised for conversions.
                          </p>
                        )}
                      </div>
                    </>
                  )}



                  {variant === "hero-3" && (
                    <p className="text-wrapper-40">
                      Say goodbye to endless tweaking - our landing page ui kit is optimised for conversions.
                    </p>
                  )}

        
                </div>
              </>
            )}

            {variant === "hero-2" && (
              <div className="overlap-3">
                <div className="ellipse-2" />
                <div className="ellipse-3" />
                <div className="ellipse-4" />
                <img
                  className="star"
                  alt="Star"
                  src={
                    screen === "mobile" ? "/img/star-2.svg" : screen === "desktop" ? "/img/star-4.svg" : "/img/star.svg"
                  }
                />
                <img
                  className="star-2"
                  alt="Star"
                  src={
                    screen === "mobile"
                      ? "/img/star-3.svg"
                      : screen === "desktop"
                      ? "/img/star-4.svg"
                      : "/img/star-1.svg"
                  }
                />
                <MobileMockup
                  buttonClassName={`${screen === "desktop" ? "class-23" : "class-24"}`}
                  buttonClassNameOverride={`${screen === "desktop" ? "class-5" : "class-6"}`}
                  className={`${screen === "desktop" ? "class-7" : "class-8"}`}
                  color="black"
                  deviceClassName={`${screen === "desktop" ? "class-15" : "class-16"}`}
                  divClassName={`${screen === "desktop" ? "class-9" : "class-10"}`}
                  divClassNameOverride={`${screen === "desktop" ? "class-17" : "class-18"}`}
                  ellipseClassName={`${screen === "desktop" ? "class-11" : "class-12"}`}
                  insertYourScreenClassName={`${screen === "desktop" ? "mobile-mockup-15" : "class-25"}`}
                  jedVillejoClassName={`${screen === "desktop" ? "class-19" : "class-20"}`}
                  overlapGroupClassName={`${screen === "desktop" ? "mobile-mockup-13" : "mobile-mockup-14"}`}
                  rectangleClassName={`${screen === "desktop" ? "class-21" : "class-22"}`}
                  topSpeakerClassName={`${screen === "desktop" ? "class-13" : "class-14"}`}
                />
              </div>
            )}
          </div>
          <div className="mockup-2">
            {["hero-3", "hero-4", "hero-5", "hero-6"].includes(variant) && (
              <div className="overlap-4">
                {variant === "hero-5" && (
                  <>
                    <div className="ellipse-5" />
                    <div className="ellipse-6" />
                    <div className="ellipse-7" />
                    <div className="desktop-mockup">
                      <div className="desktop-gray">
                        <img
                          className="mask-group"
                          alt="Mask group"
                          src={
                            screen === "tablet"
                              ? "/img/mask-group-7.png"
                              : screen === "mobile"
                              ? "/img/mask-group-8.png"
                              : "/img/mask-group-6.png"
                          }
                        />
                        <div className="top-speaker-cam">
                          <div className="rectangle-5" />
                          <div className="ellipse-8" />
                          <div className="ellipse-9" />
                        </div>
                      </div>
                    </div>
                  </>
                )}

              
                {variant === "hero-4" && (
                  <img
                    className="mask-group-2"
                    alt="Mask group"
                    src={screen === "tablet" ? "/img/mask-group-10.png" : "/img/mask-group-9.png"}
                  />
                )}

                {["hero-4", "hero-6"].includes(variant) && (
                  <div className="top-speaker-cam-2">
                    <div className="rectangle-6" />
                    {variant === "hero-4" && (
                      <>
                        <div className="ellipse-10" />
                        <div className="ellipse-11" />
                      </>
                    )}

                    {variant === "hero-6" && (
                      <>
                        <img
                          className="subtract-3"
                          alt="Subtract"
                          src={screen === "mobile" ? "/img/subtract-10.svg" : "/img/subtract-6.svg"}
                        />
                        <MobileMockup
                          buttonClassName={`${screen === "mobile" ? "class-36" : "mobile-mockup-3"}`}
                          buttonClassNameOverride={`${screen === "mobile" ? "class-26" : "mobile-mockup-4"}`}
                          className={`${screen === "mobile" ? "class-27" : "mobile-mockup-instance"}`}
                          color="black"
                          deviceClassName={`${screen === "mobile" ? "class-32" : "mobile-mockup-7"}`}
                          divClassName={`${screen === "mobile" ? "class-29" : "mobile-mockup-5"}`}
                          divClassNameOverride={`${screen === "mobile" ? "class-33" : "mobile-mockup-6"}`}
                          ellipseClassName={`${screen === "mobile" ? "class-30" : "mobile-mockup-12"}`}
                          insertYourScreenClassName={`${screen === "mobile" ? "class-37" : "mobile-mockup-8"}`}
                          jedVillejoClassName={`${screen === "mobile" ? "class-34" : "mobile-mockup-9"}`}
                          overlapGroupClassName={`${screen === "mobile" ? "class-28" : "mobile-mockup-2"}`}
                          rectangleClassName={`${screen === "mobile" ? "class-35" : "mobile-mockup-11"}`}
                          topSpeakerClassName={`${screen === "mobile" ? "class-31" : "mobile-mockup-10"}`}
                        />
                      </>
                    )}
                  </div>
                )}

                {variant === "hero-6" && (
                  <img
                    className="subtract-4"
                    alt="Subtract"
                    src={screen === "mobile" ? "/img/subtract-11.svg" : "/img/subtract-7.svg"}
                  />
                )}
              </div>
            )}

            {variant === "hero-2" && (
              <>
                <div className="header-5">
                  <div className="text-wrapper-12">Create Engaging Landing Pages</div>
                  <p className="p">
                    <span className="text-wrapper-13">Build beautiful landing pages in record time with </span>
                    <a
                      href="https://www.animaapp.com/?utm_source=figma-samples&amp;utm_campaign=figma-lp-ui-kit&amp;utm_medium=figma-samples"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className="text-wrapper-14">Anima</span>
                    </a>
                    <span className="text-wrapper-15">’s Landing Page UI kit for Figma. No code required!</span>
                  </p>
                </div>
                
              </>
            )}
          </div>
        </>
      )}

      {((screen === "mobile" && variant === "hero-1") ||
        (screen === "mobile" && variant === "hero-4") ||
        (screen === "tablet" && variant === "hero-1")) && (
        <div className="header-6">
          {variant === "hero-1" && screen === "mobile" && (
            <div className="header-7">
              <p className="meet-spontaneously">
                <span className="text-wrapper-16">
                  Meet
                  <br />
                </span>
                <span className="text-wrapper-17">spontaneously</span>
              </p>
              <p className="text-wrapper-18">
                Turn every trip into an adventure. Connect with nearby travelers and experience spontaneous moments
                together.
              </p>
            </div>
          )}

          {(screen === "tablet" || variant === "hero-4") && (
            <>
              <div className="build-live-landing">
                {variant === "hero-4" && <p className="text-wrapper-40">Build Live Landing Pages in Record Time</p>}

                {screen === "tablet" && (
                  <>
                    <p className="meet-spontaneously-2">
                      <span className="text-wrapper-19">
                        Meet
                        <br />
                      </span>
                      <span className="text-wrapper-20">spontaneously</span>
                    </p>
                    <p className="text-wrapper-21">
                      Turn every trip into an adventure. Connect with nearby travelers and experience spontaneous
                      moments together.
                    </p>
                  </>
                )}
              </div>
              <div className="say-goodbye-to">
                {variant === "hero-4" && (
                  <p className="text-wrapper-40">
                    Say goodbye to endless tweaking - our landing page ui kit is optimised for conversions.
                  </p>
                )}

                {screen === "tablet" && (
                  <div className="overlap-group-3">
                    <Button
                      buttonBaseLabelClassName="button-10"
                      buttonBaseSizeSmIconNoneClassName="button-9"
                      buttonBaseText="NEW"
                      className="button-8"
                      color="primary"
                      icon="none"
                      size="xl"
                    />
                    <Button
                      buttonBaseLabelClassName="button-10"
                      buttonBaseSizeSmIconNoneClassName="button-12"
                      buttonBaseText="TRAVEL 🗺️"
                      className="button-11"
                      color="primary"
                      icon="none"
                      size="xl"
                    />
                    <Button
                      buttonBaseLabelClassName="button-10"
                      buttonBaseSizeSmIconNoneClassName="button-14"
                      buttonBaseText="APP"
                      className="button-13"
                      color="primary"
                      icon="none"
                      size="xl"
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {variant === "hero-1" && (
        <>
          <img
            className="img-2"
            alt="Img"
            src={screen === "mobile" ? "/img/img3-2.png" : screen === "desktop" ? "/img/img-3.png" : "/img/img3-1.png"}
          />
          <img
            className="img-3"
            alt="Img"
            src={screen === "tablet" ? "/img/img2-1.png" : screen === "mobile" ? "/img/img2-2.png" : "/img/img-2.png"}
          />
        </>
      )}

      {screen === "tablet" && variant === "hero-1" && (
        <>
          <div className={`visuals ${visualsClassName}`}>
            <MobileMockup
              buttonClassName={mobileMockupButtonClassName2}
              buttonClassNameOverride={mobileMockupButtonClassName3}
              className="mobile-mockup-16"
              color="white"
              deviceClassName="mobile-mockup-17"
              divClassName={mobileMockupButtonClassName4}
              divClassNameOverride={mobileMockupButtonClassName1}
              ellipseClassName="mobile-mockup-24"
              insertYourMockup={mobileMockupImg}
              insertYourMockupClassName="mobile-mockup-20"
              insertYourScreenClassName="mobile-mockup-18"
              jedVillejoClassName="mobile-mockup-19"
              jedVillejoClassNameOverride="mobile-mockup-21"
              overlapGroupClassName="mobile-mockup-14"
              rectangleClassName="mobile-mockup-23"
              topSpeakerClassName="mobile-mockup-22"
            />
          </div>
          <img className="IMG-2" alt="Img" src="/img/img-3517-2.png" />
          <Mensajes
            chatClassName="mensajes-3"
            className="mensajes-instance"
            divClassName="mensajes-4"
            hasFrame={false}
            polygon={mensajesImg}
            rectangleClassName="mensajes-2"
            text="Hey guys!! I am getting ready"
            type="t"
          />
          <Mensajes
            chatClassName="mensajes-3"
            className="mensajes-5"
            divClassName="mensajes-4"
            hasFrame={false}
            img={mensajesPolygon1}
            polygon={mensajesPolygon3}
            rectangleClassName="mensajes-2"
            text="Should we meet at the entrance?"
            type="t"
          />
          <Mensajes
            chatClassName="mensajes-7"
            className="mensajes-6"
            divClassNameOverride="mensajes-9"
            hasDiv={false}
            hasRectangle={false}
            img={mensajesPolygon2}
            text="Yes, see you there!"
            textClassName="mensajes-8"
            type="otro"
            visible={false}
          />
          <Mensajes
            chatClassName="mensajes-7"
            className="mensajes-10"
            divClassNameOverride="mensajes-9"
            hasDiv={false}
            hasRectangle={false}
            img={mensajesPolygon4}
            text="Bet, be there in 5"
            textClassName="mensajes-8"
            type="otro"
            visible={false}
          />
        </>
      )}

      {variant === "hero-1" && screen === "mobile" && (
        <>
          <div className={`visuals-2 ${visualsClassName}`}>
            <MobileMockup
              buttonClassName={mobileMockupButtonClassNameOverride}
              buttonClassNameOverride={mobileMockupDivClassName}
              className="mobile-mockup-25"
              color="white"
              deviceClassName={mobileMockupDeviceClassName}
              divClassName={mobileMockupDivClassNameOverride}
              divClassNameOverride={mobileMockupButtonClassName}
              ellipseClassName="mobile-mockup-24"
              insertYourMockup={mobileMockupInsertYourMockup}
              insertYourMockupClassName="mobile-mockup-20"
              insertYourScreenClassName="mobile-mockup-18"
              jedVillejoClassName="mobile-mockup-19"
              jedVillejoClassNameOverride="mobile-mockup-21"
              overlapGroupClassName={mobileMockupOverlapGroupClassName}
              rectangleClassName="mobile-mockup-23"
              topSpeakerClassName={mobileMockupTopSpeakerClassName}
            />
            <img className="IMG-3" alt="Img" src="/img/img-3517-3.png" />
          </div>
          <Mensajes
            chatClassName="mensajes-12"
            className="mensajes-11"
            divClassName="mensajes-4"
            hasFrame={false}
            polygon={mensajesPolygon}
            rectangleClassName="mensajes-2"
            text="Hey guys!! I am getting ready"
            type="t"
          />
          <Mensajes
            chatClassName="mensajes-14"
            className="mensajes-13"
            divClassName="mensajes-4"
            hasFrame={false}
            polygon={mensajesImg}
            rectangleClassName="mensajes-2"
            text="Should we meet at the entrance?"
            type="t"
          />
          <Mensajes
            chatClassName="mensajes-16"
            className="mensajes-15"
            divClassNameOverride="mensajes-9"
            hasDiv={false}
            hasRectangle={false}
            img={mensajesPolygon1}
            polygon={mensajesPolygon3}
            text="Yes, see you there!"
            textClassName="mensajes-8"
            type="otro"
            visible={false}
          />
          <Mensajes
            chatClassName="mensajes-18"
            className="mensajes-17"
            divClassNameOverride="mensajes-9"
            hasDiv={false}
            hasRectangle={false}
            img={mensajesPolygon2}
            text="Bet, be there in 5"
            textClassName="mensajes-8"
            type="otro"
            visible={false}
          />
          <div className="group">
            <div className="overlap-group-3">
              <Button
                buttonBaseLabelClassName="button-10"
                buttonBaseSizeSmIconNoneClassName="button-9"
                buttonBaseText="NEW"
                className="button-8"
                color="primary"
                icon="none"
                size="xl"
              />
              <Button
                buttonBaseLabelClassName="button-10"
                buttonBaseSizeSmIconNoneClassName="button-12"
                buttonBaseText="TRAVEL 🗺️"
                className="button-11"
                color="primary"
                icon="none"
                size="xl"
              />
              <Button
                buttonBaseLabelClassName="button-10"
                buttonBaseSizeSmIconNoneClassName="button-14"
                buttonBaseText="APP"
                className="button-13"
                color="primary"
                icon="none"
                size="xl"
              />
            </div>
          </div>
        </>
      )}

      {variant === "hero-4" && screen === "mobile" && (
        <>
          
          <div className="desktop-gray-wrapper">
            <div className="desktop-gray-2">
              <img className="mask-group-3" alt="Mask group" src="/img/mask-group-11.png" />
              <div className="top-speaker-cam-3">
                <div className="rectangle-7" />
                <div className="ellipse-12" />
                <div className="ellipse-13" />
              </div>
            </div>
          </div>
        </>
      )}

      {screen === "desktop" && variant === "hero-1" && (
        <>
          <div className="header-wrapper">
            <div className="header-8">
              <p className="meet-spontaneously-3">
                <span className="text-wrapper-19">
                  Meet
                  <br />
                </span>
                <span className="text-wrapper-20">spontaneously</span>
              </p>
              <p className="text-wrapper-22">
                Turn every trip into an adventure. Connect with nearby travelers and experience spontaneous moments
                together.
              </p>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-3">
                  <Button
                    buttonBaseLabelClassName="button-10"
                    buttonBaseSizeSmIconNoneClassName="button-9"
                    buttonBaseText="NEW"
                    className="button-8"
                    color="primary"
                    icon="none"
                    size="xl"
                  />
                  <Button
                    buttonBaseLabelClassName="button-10"
                    buttonBaseSizeSmIconNoneClassName="button-12"
                    buttonBaseText="TRAVEL 🗺️"
                    className="button-11"
                    color="primary"
                    icon="none"
                    size="xl"
                  />
                  <Button
                    buttonBaseLabelClassName="button-10"
                    buttonBaseSizeSmIconNoneClassName="button-14"
                    buttonBaseText="APP"
                    className="button-13"
                    color="primary"
                    icon="none"
                    size="xl"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`mobile-mockup-wrapper ${visualsClassName}`}>
            <MobileMockup
              buttonClassName={mobileMockupButtonClassName2}
              buttonClassNameOverride={mobileMockupButtonClassName3}
              className="mobile-mockup-26"
              color="white"
              deviceClassName="mobile-mockup-27"
              divClassName={mobileMockupButtonClassName4}
              divClassNameOverride={mobileMockupButtonClassName1}
              ellipseClassName="mobile-mockup-33"
              insertYourMockup={mobileMockupImg}
              insertYourMockupClassName="mobile-mockup-29"
              insertYourScreenClassName="mobile-mockup-15"
              jedVillejoClassName="mobile-mockup-28"
              jedVillejoClassNameOverride="mobile-mockup-30"
              overlapGroupClassName="mobile-mockup-13"
              rectangleClassName="mobile-mockup-32"
              topSpeakerClassName="mobile-mockup-31"
            />
          </div>
          <img className="IMG-4" alt="Img" src="/img/img-3517-1.png" />
          <Mensajes
            chatClassName="mensajes-20"
            className="mensajes-19"
            divClassNameOverride="mensajes-9"
            hasDiv={false}
            hasRectangle={false}
            img="/img/polygon-1.svg"
            polygon={mensajesImg}
            text="Bet, be there in 5 :)"
            textClassName="mensajes-8"
            type="otro"
            visible={false}
          />
          <Mensajes
            chatClassName="mensajes-7"
            className="mensajes-21"
            divClassNameOverride="mensajes-9"
            hasDiv={false}
            hasRectangle={false}
            img={mensajesPolygon1}
            polygon={mensajesPolygon3}
            text="Yes, see you there!"
            textClassName="mensajes-8"
            type="otro"
            visible={false}
          />
          <Mensajes
            chatClassName="mensajes-3"
            className="mensajes-22"
            divClassName="mensajes-4"
            hasFrame={false}
            img={mensajesPolygon2}
            polygon="/img/polygon-1-2.svg"
            rectangleClassName="mensajes-2"
            text="Hey guys!! I am getting ready"
            type="t"
          />
          <Mensajes
            chatClassName="mensajes-3"
            className="mensajes-23"
            divClassName="mensajes-4"
            hasFrame={false}
            img={mensajesPolygon4}
            polygon="/img/polygon-1-2.svg"
            rectangleClassName="mensajes-2"
            text="Should we meet at the entrance?"
            type="t"
          />
        </>
      )}
    </div>
  );
};

HeroSection.propTypes = {
  variant: PropTypes.oneOf(["hero-4", "hero-5", "hero-3", "hero-1", "hero-6", "hero-2"]),
  screen: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
  mobileMockupInsertYourMockup: PropTypes.string,
  mensajesPolygon: PropTypes.string,
  mensajesImg: PropTypes.string,
  mensajesPolygon1: PropTypes.string,
  mensajesPolygon2: PropTypes.string,
  mobileMockupImg: PropTypes.string,
  mensajesPolygon3: PropTypes.string,
  mensajesPolygon4: PropTypes.string,
};
