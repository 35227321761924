/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Envelopesimple = ({ className }) => {
  return (
    <svg
      className={`envelopesimple ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M21 5.48071L12 13.7307L3 5.48071H21Z" fill="#219F56" opacity="0.2" />
      <path
        className="path"
        d="M3 5.48071H21V18.2307C21 18.4296 20.921 18.6204 20.7803 18.761C20.6397 18.9017 20.4489 18.9807 20.25 18.9807H3.75C3.55109 18.9807 3.36032 18.9017 3.21967 18.761C3.07902 18.6204 3 18.4296 3 18.2307V5.48071Z"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M21 5.48071L12 13.7307L3 5.48071"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
