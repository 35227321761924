/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ButtonBase } from "../ButtonBase";
import "./style.css";

export const Button = ({
  size,
  color,
  icon,
  className,
  buttonBaseSizeSmIconNoneClassName,
  buttonBaseLabelClassName,
  buttonBaseText = "Button",
}) => {
  return (
    <div className={`button ${color} icon-${icon} ${className}`}>
      <ButtonBase
        className={buttonBaseSizeSmIconNoneClassName}
        divClassName={`${
          ((color === "link-color" && icon === "leading") ||
            (color === "secondary-color" && icon === "leading") ||
            (color === "secondary-white" && icon === "leading") ||
            (color === "tertiary" && icon === "leading")) &&
          "class-3"
        }`}
        essentialIcon16Color={
          (color === "link-color" && icon === "trailing" && size === "lg") ||
          (color === "link-color" && icon === "trailing" && size === "md") ||
          (color === "link-color" && icon === "trailing" && size === "sm") ||
          (color === "link-color" && icon === "trailing" && size === "xs") ||
          (color === "secondary-color" && icon === "trailing" && size === "lg") ||
          (color === "secondary-color" && icon === "trailing" && size === "md") ||
          (color === "secondary-color" && icon === "trailing" && size === "sm") ||
          (color === "secondary-color" && icon === "trailing" && size === "xs") ||
          (color === "secondary-white" && icon === "trailing" && size === "lg") ||
          (color === "secondary-white" && icon === "trailing" && size === "md") ||
          (color === "secondary-white" && icon === "trailing" && size === "sm") ||
          (color === "secondary-white" && icon === "trailing" && size === "xs") ||
          (color === "tertiary" && icon === "trailing" && size === "lg") ||
          (color === "tertiary" && icon === "trailing" && size === "md") ||
          (color === "tertiary" && icon === "trailing" && size === "sm") ||
          (color === "tertiary" && icon === "trailing" && size === "xs")
            ? "#7C3AED"
            : (color === "link-white" && icon === "trailing" && size === "lg") ||
              (color === "link-white" && icon === "trailing" && size === "md") ||
              (color === "link-white" && icon === "trailing" && size === "sm") ||
              (color === "link-white" && icon === "trailing" && size === "xs") ||
              (color === "primary" && icon === "trailing" && size === "lg") ||
              (color === "primary" && icon === "trailing" && size === "md") ||
              (color === "primary" && icon === "trailing" && size === "sm") ||
              (color === "primary" && icon === "trailing" && size === "xs")
            ? "white"
            : undefined
        }
        essentialIcon16Color1={
          (color === "link-color" && icon === "only" && size === "lg") ||
          (color === "link-color" && icon === "only" && size === "md") ||
          (color === "link-color" && icon === "only" && size === "sm") ||
          (color === "link-color" && icon === "only" && size === "xs") ||
          (color === "secondary-color" && icon === "only" && size === "lg") ||
          (color === "secondary-color" && icon === "only" && size === "md") ||
          (color === "secondary-color" && icon === "only" && size === "sm") ||
          (color === "secondary-color" && icon === "only" && size === "xs") ||
          (color === "secondary-white" && icon === "only" && size === "lg") ||
          (color === "secondary-white" && icon === "only" && size === "md") ||
          (color === "secondary-white" && icon === "only" && size === "sm") ||
          (color === "secondary-white" && icon === "only" && size === "xs") ||
          (color === "tertiary" && icon === "only" && size === "lg") ||
          (color === "tertiary" && icon === "only" && size === "md") ||
          (color === "tertiary" && icon === "only" && size === "sm") ||
          (color === "tertiary" && icon === "only" && size === "xs")
            ? "#7C3AED"
            : (color === "link-white" && icon === "only" && size === "lg") ||
              (color === "link-white" && icon === "only" && size === "md") ||
              (color === "link-white" && icon === "only" && size === "sm") ||
              (color === "link-white" && icon === "only" && size === "xs") ||
              (color === "primary" && icon === "only" && size === "lg") ||
              (color === "primary" && icon === "only" && size === "md") ||
              (color === "primary" && icon === "only" && size === "sm") ||
              (color === "primary" && icon === "only" && size === "xs")
            ? "white"
            : undefined
        }
        essentialIcon16Stroke={
          (color === "link-color" && icon === "leading" && size === "lg") ||
          (color === "link-color" && icon === "leading" && size === "md") ||
          (color === "link-color" && icon === "leading" && size === "sm") ||
          (color === "link-color" && icon === "leading" && size === "xs") ||
          (color === "secondary-color" && icon === "leading" && size === "lg") ||
          (color === "secondary-color" && icon === "leading" && size === "md") ||
          (color === "secondary-color" && icon === "leading" && size === "sm") ||
          (color === "secondary-color" && icon === "leading" && size === "xs") ||
          (color === "secondary-white" && icon === "leading" && size === "lg") ||
          (color === "secondary-white" && icon === "leading" && size === "md") ||
          (color === "secondary-white" && icon === "leading" && size === "sm") ||
          (color === "secondary-white" && icon === "leading" && size === "xs") ||
          (color === "tertiary" && icon === "leading" && size === "lg") ||
          (color === "tertiary" && icon === "leading" && size === "md") ||
          (color === "tertiary" && icon === "leading" && size === "sm") ||
          (color === "tertiary" && icon === "leading" && size === "xs")
            ? "#7C3AED"
            : (color === "link-white" && icon === "leading" && size === "lg") ||
              (color === "link-white" && icon === "leading" && size === "md") ||
              (color === "link-white" && icon === "leading" && size === "sm") ||
              (color === "link-white" && icon === "leading" && size === "xs") ||
              (color === "primary" && icon === "leading" && size === "lg") ||
              (color === "primary" && icon === "leading" && size === "md") ||
              (color === "primary" && icon === "leading" && size === "sm") ||
              (color === "primary" && icon === "leading" && size === "xs")
            ? "white"
            : undefined
        }
        essentialIcon49Color={
          (color === "link-color" && icon === "trailing" && size === "xl") ||
          (color === "secondary-color" && icon === "trailing" && size === "xl") ||
          (color === "secondary-white" && icon === "trailing" && size === "xl") ||
          (color === "tertiary" && icon === "trailing" && size === "xl")
            ? "#7C3AED"
            : icon === "trailing" && size === "xl" && ["link-white", "primary"].includes(color)
            ? "white"
            : undefined
        }
        essentialIcon49Color1={
          (color === "link-color" && icon === "only" && size === "xl") ||
          (color === "secondary-color" && icon === "only" && size === "xl") ||
          (color === "secondary-white" && icon === "only" && size === "xl") ||
          (color === "tertiary" && icon === "only" && size === "xl")
            ? "#7C3AED"
            : icon === "only" && size === "xl" && ["link-white", "primary"].includes(color)
            ? "white"
            : undefined
        }
        essentialIcon49Stroke={
          (color === "link-color" && icon === "leading" && size === "xl") ||
          (color === "secondary-color" && icon === "leading" && size === "xl") ||
          (color === "secondary-white" && icon === "leading" && size === "xl") ||
          (color === "tertiary" && icon === "leading" && size === "xl")
            ? "#7C3AED"
            : icon === "leading" && size === "xl" && ["link-white", "primary"].includes(color)
            ? "white"
            : undefined
        }
        icon={icon === "trailing" ? "trailing" : icon === "leading" ? "leading" : icon === "only" ? "only" : "none"}
        labelClassName={buttonBaseLabelClassName}
        labelClassNameOverride={`${
          ((color === "link-color" && icon === "trailing") ||
            (color === "secondary-color" && icon === "trailing") ||
            (color === "secondary-white" && icon === "trailing") ||
            (color === "tertiary" && icon === "trailing")) &&
          "class-3"
        }`}
        size={size === "md" ? "md" : size === "xs" ? "xs" : size === "xl" ? "xl" : size === "lg" ? "lg" : "sm"}
        text={buttonBaseText}
      />
    </div>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(["sm", "xs", "lg", "xl", "md"]),
  color: PropTypes.oneOf(["link-white", "link-color", "tertiary", "secondary-color", "primary", "secondary-white"]),
  icon: PropTypes.oneOf(["none", "leading", "only", "trailing"]),
  buttonBaseText: PropTypes.string,
};
