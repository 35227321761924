/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Envelopesimple9 = ({ className }) => {
  return (
    <svg
      className={`envelopesimple-9 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M17.5 4.375L10 11.25L2.5 4.375H17.5Z" fill="#219F56" opacity="0.2" />
      <path
        className="path"
        d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.740741"
      />
      <path
        className="path"
        d="M17.5 4.375L10 11.25L2.5 4.375"
        stroke="#219F56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.740741"
      />
    </svg>
  );
};
