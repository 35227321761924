/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Footer = ({
  screen,
  className,
  socialLinks = "/img/social-links-2.svg",
  img = "/img/social-links-1.svg",
  socialLinks1 = "/img/social-links.svg",
}) => {
  return (
    <div className={`footer screen-105-${screen} ${className}`}>
      {["desktop", "tablet", "mobile"].includes(screen) && (
        <p className="element-anima-landing">© 2024 WeOut LLC. All rights reserved.</p>
      )}

      
    </div>
  );
};

Footer.propTypes = {
  screen: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
  socialLinks: PropTypes.string,
  img: PropTypes.string,
  socialLinks1: PropTypes.string,
};
