
import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Navbar = ({ variant, screen, variantScreenClassName }) => {
  return (
    <>
      {(screen === "desktop" ||
        (screen === "mobile" && variant === "eight") ||
        (screen === "mobile" && variant === "five") ||
        (screen === "mobile" && variant === "four") ||
        (screen === "mobile" && variant === "one") ||
        (screen === "mobile" && variant === "seven") ||
        (screen === "mobile" && variant === "three") ||
        screen === "tablet") && (
        <div className={`navbar variant-screen ${variant} ${screen} ${variantScreenClassName}`}>
          {((screen === "desktop" && variant === "eight") ||
            (screen === "desktop" && variant === "five") ||
            (screen === "desktop" && variant === "one") ||
            (screen === "desktop" && variant === "three") ||
            (screen === "mobile" && variant === "eight") ||
            (screen === "mobile" && variant === "five") ||
            (screen === "mobile" && variant === "one") ||
            (screen === "tablet" && variant === "four") ||
            variant === "seven" ||
            variant === "six" ||
            variant === "two") && (
            <div className="logo">
              {((screen === "desktop" && variant === "five") ||
                (screen === "desktop" && variant === "six") ||
                (screen === "desktop" && variant === "two") ||
                variant === "one" ||
                variant === "three") && (
                <>
                  <img className="television" alt="Television" src="/img/television.png" />
                  <p className="div">
                    <span className="text-wrapper">Landing</span>
                    <span className="span">Page</span>
                  </p>
                </>
              )}

              {((screen === "mobile" && variant === "eight") ||
                (screen === "mobile" && variant === "seven") ||
                (screen === "tablet" && variant === "seven")) && (
                <TypePrimaryWrapper
                  className="button-instance"
                  hasLeftIcon={false}
                  hasRightIcon={variant === "seven" ? false : undefined}
                  text={variant === "eight" ? "Get Started" : "How it works"}
                  type={variant === "eight" ? "primary" : "secondary-color"}
                />
              )}

              {variant === "seven" && screen === "tablet" && (
                <TypePrimaryWrapper className="button-instance" hasLeftIcon={false} text="Get Started" type="primary" />
              )}

              {((screen === "desktop" && variant === "eight") ||
                (screen === "desktop" && variant === "seven") ||
                (screen === "tablet" && variant === "six") ||
                variant === "four") && (
                <div className="logo-2">
                  <img
                    className="television"
                    alt="Television"
                    src={variant === "four" ? "/img/television-6.png" : "/img/television.png"}
                  />
                  <p className="div">
                    <span className="text-wrapper">Landing</span>
                    <span className="span">Page</span>
                  </p>
                </div>
              )}

              {screen === "desktop" && ["eight", "seven"].includes(variant) && (
                <div className="navigation-menu">
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    hasRightIcon={false}
                    text="Menu 1"
                    type="tertiary"
                  />
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    hasRightIcon={false}
                    text="Menu 2"
                    type="tertiary"
                  />
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    hasRightIcon={false}
                    text="Menu 3"
                    type="tertiary"
                  />
                </div>
              )}

              {["mobile", "tablet"].includes(screen) &&
                (screen === "mobile" || variant === "two") &&
                (screen === "tablet" || variant === "five") &&
                ["five", "two"].includes(variant) && <img className="IMG" alt="Img" src="/img/img-3859-1.png" />}
            </div>
          )}

          {variant === "four" && screen === "desktop" && <img className="img" alt="Img" src="/img/img-3859-1.png" />}

          {((screen === "desktop" && variant === "five") ||
            (screen === "desktop" && variant === "one") ||
            (screen === "desktop" && variant === "seven") ||
            (screen === "desktop" && variant === "three") ||
            (screen === "desktop" && variant === "two") ||
            (screen === "tablet" && variant === "seven") ||
            variant === "six") && (
            <div className="navigation-menu-2">
              {((screen === "desktop" && variant === "seven") ||
                (screen === "desktop" && variant === "six") ||
                variant === "five" ||
                variant === "one" ||
                variant === "two") && (
                <TypePrimaryWrapper
                  className="button-instance"
                  hasLeftIcon={false}
                  hasRightIcon={false}
                  text={variant === "seven" ? "How it works" : "Menu 1"}
                  type={variant === "seven" ? "secondary-color" : "tertiary"}
                />
              )}

              {(variant === "five" ||
                variant === "one" ||
                variant === "two" ||
                (screen === "desktop" && variant === "six")) && (
                <>
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    hasRightIcon={false}
                    text="Menu 2"
                    type="tertiary"
                  />
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    hasRightIcon={false}
                    text="Menu 3"
                    type="tertiary"
                  />
                </>
              )}

              {(variant === "two" ||
                (screen === "desktop" && variant === "seven") ||
                (screen === "tablet" && variant === "six")) && (
                <TypePrimaryWrapper className="button-instance" hasLeftIcon={false} text="Get Started" type="primary" />
              )}

              {(variant === "one" || variant === "three" || (screen === "tablet" && variant === "seven")) && (
                <div className="logo-3">
                  {variant === "seven" && (
                    <>
                      <img className="television" alt="Television" src="/img/television.png" />
                      <p className="div">
                        <span className="text-wrapper">Landing</span>
                        <span className="span">Page</span>
                      </p>
                    </>
                  )}

                  {screen === "desktop" && (
                    <>
                      <TypePrimaryWrapper
                        className="button-instance"
                        hasLeftIcon={false}
                        hasRightIcon={false}
                        text="How it works"
                        type="secondary-color"
                      />
                      <TypePrimaryWrapper
                        className="button-instance"
                        hasLeftIcon={false}
                        text="Get Started"
                        type="primary"
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          {((screen === "desktop" && variant === "eight") ||
            (screen === "desktop" && variant === "six") ||
            (screen === "tablet" && variant === "eight")) && (
            <TypePrimaryWrapper className="button-instance" hasLeftIcon={false} text="Get Started" type="primary" />
          )}

          {((screen === "mobile" && variant === "eight") ||
            (screen === "mobile" && variant === "four") ||
            (screen === "mobile" && variant === "seven") ||
            (screen === "mobile" && variant === "three") ||
            (screen === "tablet" && variant === "eight") ||
            (screen === "tablet" && variant === "five") ||
            (screen === "tablet" && variant === "one") ||
            (screen === "tablet" && variant === "three")) && <img className="logo-4" alt="Logo" src="/img/logo.svg" />}

          {((screen === "mobile" && variant === "four") ||
            (screen === "mobile" && variant === "three") ||
            (screen === "tablet" && variant === "five") ||
            (screen === "tablet" && variant === "one") ||
            (screen === "tablet" && variant === "three")) && (
            <div className="navigation-menu-3">
              {["five", "one"].includes(variant) && (
                <div className="highlighted-ctas">
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    hasRightIcon={false}
                    text="How it works"
                    type="secondary-color"
                  />
                  <TypePrimaryWrapper
                    className="button-instance"
                    hasLeftIcon={false}
                    text="Get Started"
                    type="primary"
                  />
                </div>
              )}

              {(screen === "mobile" || variant === "three") && (
                <TypePrimaryWrapper
                  className="button-instance"
                  hasLeftIcon={false}
                  hasRightIcon={variant === "three" ? false : undefined}
                  text={variant === "four" ? "Get Started" : "How it works"}
                  type={variant === "four" ? "primary" : "secondary-color"}
                />
              )}

              {screen === "tablet" && variant === "three" && (
                <TypePrimaryWrapper className="button-instance" hasLeftIcon={false} text="Get Started" type="primary" />
              )}
            </div>
          )}

          {screen === "desktop" && variant === "five" && (
            <div className="highlighted-ctas">
              <TypePrimaryWrapper
                className="button-instance"
                hasLeftIcon={false}
                hasRightIcon={false}
                text="How it works"
                type="secondary-color"
              />
              <TypePrimaryWrapper className="button-instance" hasLeftIcon={false} text="Get Started" type="primary" />
            </div>
          )}
        </div>
      )}

      {screen === "mobile" && ["six", "two"].includes(variant) && (
        <img
          className={`navbar variant-screen-2 ${variantScreenClassName}`}
          alt="Variant screen"
          src={variant === "six" ? "/img/variant-6-screen-mobile.svg" : "/img/variant-2-screen-mobile.svg"}
        />
      )}
    </>
  );
};

Navbar.propTypes = {
  variant: PropTypes.oneOf(["seven", "two", "three", "one", "four", "five", "eight", "six"]),
  screen: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
};
